import { ReactNode } from "react";
import {
  DMModal,
  DMModalContent,
  DMModalFooter,
  DMModalProps,
  DMModalTitle,
} from ".";
import Button from "../../student/components/generic/button";

type DMModalPropsWithoutOnClose = Omit<DMModalProps, "onClose">;

interface DMConfirmModalProps {
  /** A title for the alert. _Optional_ */
  title?: ReactNode;
  /**
   * The text to display on the confirmation button
   * @default "OK"
   */
  confirmText?: ReactNode;
  /** Action to perform when clicking the confirm button */
  onConfirm: () => void;
  /**
   * The text to display on the cancel button
   * @default "Cancel"
   */
  cancelText?: ReactNode;
  /** Action to perform when clicking the cancel button */
  onCancel: () => void;
}

/** A simple, customizeable alert modal based on `DMModal` */
export const DMConfirmModal: React.FC<
  DMConfirmModalProps & DMModalPropsWithoutOnClose
> = ({
  title,
  confirmText = "OK",
  onConfirm,
  cancelText = "Cancel",
  onCancel,
  visible,
  children,
  ...props
}) => (
  <DMModal visible={visible} onClose={onCancel} {...props}>
    {title && <DMModalTitle>{title}</DMModalTitle>}
    <DMModalContent>{children}</DMModalContent>
    <DMModalFooter>
      <Button type="outline" onClick={onCancel}>
        {cancelText}
      </Button>
      <Button onClick={onConfirm}>{confirmText}</Button>
    </DMModalFooter>
  </DMModal>
);
