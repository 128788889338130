import { Learner } from "../../types";
import { ResendInviteLink } from "../Profile/ResendInviteLink";
import { CopyInviteLink } from "./CopyInviteLink";
import { WarningBanner } from "./WarningBanner";

export const InvitedLearnerWarningBanner: React.FC<{
  singleLearner?: Pick<Learner, "_id" | "inviteCode">;
}> = ({ singleLearner }) => (
  <>
    {singleLearner ? (
      <WarningBanner bannerTitle="Your learner's invite has been sent to their email! Check their inbox and let them know to accept it!">
        <span className="font-bold">
          <ResendInviteLink
            learnerId={singleLearner._id}
            className="!py-0 !text-base hover:underline"
            buttonText="Resend invite"
          />
          {singleLearner.inviteCode && (
            <>
              {" or "}
              <CopyInviteLink
                inviteCode={singleLearner.inviteCode}
                className="!py-0 hover:underline"
              />
            </>
          )}
        </span>
      </WarningBanner>
    ) : (
      <WarningBanner bannerTitle="Your learner's invite has been sent to their email! Check their inbox and let them know to accept it! You can resend invite links or copy invite links below on Learner Account Management." />
    )}
  </>
);
