import Integrations from "./Integrations";
import { useDocumentTitle } from "usehooks-ts";
import {
  DataTableContextProvider,
  useDataTable,
} from "../DataTable/DataTableContext";
import { DataTable } from "../DataTable/DataTable";
import Button from "../../../student/components/generic/button";
import { EmptyCell } from "../DataTable/EmptyCell";

export interface ILtiLog {
  timestamp?: number;
  tool_consumer_info_product_family_code?: string;
  lis_person_name_full?: string;
  lis_person_contact_email_primary?: string;
  context_id?: string;
  context_type?: string;
  context_title?: string;
  resource_link_id?: string;
  resource_link_title?: string;
  oauth_consumer_key?: string;
  custom_section_names?: string;
}

export interface IIntegration {
  key: string;
  secret: string;
  one_roster_key?: string;
  one_roster_secret?: string;
  one_roster_url?: string;
  rostering_domains?: string[];
  nces_id?: string;
  nces_ids?: string[];
  site_type?: string;
  classlink_tenant_id?: number;
  clever_district_id?: string | string[];
  clever_token?: string | string[];

  teachercode?: string;
  opt_out_rostered_launch?: boolean;
  opt_out_rostered_launch_fully?: boolean;
  use_enrollments?: boolean;
}

const LTILogs = () => {
  useDocumentTitle("LTI Logs");

  return (
    <DataTableContextProvider
      source={`/manager_new/integrations/launch-logs`}
      defaults={{ sortBy: [{ id: "timestamp", desc: true }], pageSize: 50 }}
      sortable={["timestamp"]}
      columnOverrides={{
        custom_section_names: ({ value }) =>
          !value ? (
            <EmptyCell />
          ) : typeof value === "string" ? (
            <pre>{JSON.stringify(JSON.parse(value), null, 2)}</pre>
          ) : (
            <pre>{String(value)}</pre>
          ),
      }}
    >
      <LTILogsContent />
    </DataTableContextProvider>
  );
};

const LTILogsContent = () => {
  const { isFetching, integrations, setExternalFilterBy } = useDataTable<{
    integrations: IIntegration[];
    logs: ILtiLog[];
  }>();
  return (
    <div className="flex flex-col gap-8 p-6">
      <div className="relative">
        <h1 className="text-2xl font-bold">LTI Logs</h1>
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-4">
            <form
              className="mt-2"
              onSubmit={(event) => {
                event.preventDefault();
                const email = new FormData(event.target as HTMLFormElement).get(
                  "email"
                ) as string;
                setExternalFilterBy([
                  { id: "lis_person_contact_email_primary", value: email },
                ]);
              }}
            >
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Search by Email
              </label>
              <div className="flex gap-2 sm:max-w-md">
                <input
                  type="text"
                  name="email"
                  id="email"
                  className="h-8 flex-1 rounded border-dm-charcoal-200 bg-transparent bg-white px-2 text-gray-900 shadow-none outline-none focus:border-gray-300 focus:ring-0"
                />
                <Button className="h-8 !py-0 px-4">Search &rarr;</Button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {integrations && (
        <Integrations integrations={integrations} isFetching={isFetching} />
      )}

      <DataTable />
    </div>
  );
};

export default LTILogs;
