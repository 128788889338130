import { useDataTable } from "./DataTableContext";
import { TableBodyCell } from "./TableBodyCell";

export const TableBody: React.FC = () => {
  const { table } = useDataTable();
  return (
    <tbody
      {...table.getTableBodyProps()}
      className="divide-y divide-dm-charcoal-100 bg-white"
    >
      {table.rows.length > 0 ? (
        table.rows.map((row) => {
          table.prepareRow(row);
          return (
            // `getRowProps` includes `key`
            // eslint-disable-next-line react/jsx-key
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <TableBodyCell
                  key={`${cell.column.id}-${cell.row.id}`}
                  cell={cell}
                />
              ))}
            </tr>
          );
        })
      ) : (
        <tr>
          <td
            colSpan={table.visibleColumns.length}
            className="px-6 py-10 text-center text-dm-charcoal-500/80"
          >
            No results found
          </td>
        </tr>
      )}
    </tbody>
  );
};
