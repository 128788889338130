import { useEffect } from "react";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { deltamathAPI } from "../../../manager/utils";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useUserContext } from "../../../shared/contexts/UserContext";
import { REACT_APP_STUDENT_LINK } from "../../../utils";

export interface ILtiContext {
  context_id: string;
  context_label: string;
  context_title: string;
  context_type: string;
  consumer_key: string;
  resource_link_title: string;
  resource_link_id: string;
  lis_outcome_service_url?: string;
  lis_result_sourcedid?: string;
  isLtiResourceLaunch?: boolean;
  lms_id?: string;
  lti_int_id?: string;
  due_at_timestamp: number;
}

const LtiLogin = () => {
  const { ltiId: ltiId } = useParams();
  const userContext = useUserContext();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const navigate = useNavigate();

  // Incase someone has been impersonating, LTI wants to ignore it no matter what
  window.localStorage.removeItem("impersonateStudent");

  const ltiAuth = useMutation(() => {
    return axios.post(
      `${deltamathAPI()}/lti_auth`,
      {
        params: {
          auth_code: ltiId,
          version: `405`,
          iframe: true,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  });

  useEffect(() => {
    if (ltiId !== "error") {
      ltiAuth.mutate();
    }
  }, [ltiId]);

  useEffect(() => {
    if (ltiAuth.status === "success") {
      if (ltiAuth.data && ltiAuth.data.data) {
        const data: {
          loginData: any;
          url: string;
          lti_context?: ILtiContext;
          assignment?: any;
          studentAssignmentsStarted: boolean;
          req: any;
        } = ltiAuth.data.data as any;

        const decodedJwt: any =
          data.loginData && data.loginData.jwt
            ? jwt_decode(data.loginData.jwt)
            : undefined;

        if (data.loginData) {
          if (data.loginData.jwt) {
            userContext.setJWT(data.loginData.jwt);
          }
          if (data.loginData.user) {
            localStorage.setItem("user", JSON.stringify(data.loginData.user));
          }
        }
        if (data.lti_context) {
          localStorage.setItem(
            "lti_assignment_payload",
            JSON.stringify({
              lti_context: data.lti_context,
              assignment: data.assignment,
              studentAssignmentsStarted: data.studentAssignmentsStarted,
              isLtiResourceLaunch: data.lti_context.isLtiResourceLaunch,
              url: data.url,
            })
          );
        }
        if (
          decodedJwt &&
          decodedJwt.data &&
          decodedJwt.data.ltiLock &&
          decodedJwt.data.ltiLock.taId
        ) {
          navigate(
            `${REACT_APP_STUDENT_LINK}/link/${decodedJwt.data.ltiLock.taId}`
          );
        } else {
          navigate(`${REACT_APP_STUDENT_LINK}`);
        }
      }
    }
  }, [ltiAuth.status]);

  if (ltiId !== "error" && !urlParams.get("error")) {
    return <div></div>;
  }

  return (
    <div className="sm:px-auto fixed inset-0 flex flex-col items-center justify-center bg-gray-600 px-2">
      <div className="max-h-screen w-full max-w-2xl overflow-y-auto rounded-xl bg-white p-4 text-left sm:px-8 sm:py-12">
        <h1 className="mb-4 font-serif text-2xl font-semibold tracking-tight">
          LTI Launch Error
        </h1>
        <p className="text-sm tracking-tight">{urlParams.get("error")}</p>
      </div>
    </div>
  );
};
export default LtiLogin;
