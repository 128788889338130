import clsx from "clsx";
import { ColumnInstance } from "react-table";
import { useDataTable } from "./DataTableContext";

export const TableHeaderCell: React.FC<{
  column: ColumnInstance;
}> = ({ column }) => {
  const { sortable } = useDataTable();
  // column.canSort is always true?
  const canSort = sortable.includes(column.id);
  return (
    <th
      {...column.getHeaderProps(canSort ? column.getSortByToggleProps() : {})}
      scope="col"
      className={clsx(
        "group bg-dm-charcoal-500 px-4 py-2 align-bottom font-normal text-white",
        column.align === "right" ? "text-right" : "text-left"
      )}
    >
      <span className="inline-flex w-full flex-col gap-1">
        <span className="inline-flex w-full justify-between">
          {column.render("Header")}
          {canSort && (
            <span
              className={clsx(
                "ml-1 group-hover:opacity-100",
                column.isSorted ? "opacity-100" : "opacity-50"
              )}
              {...column.getSortByToggleProps()}
            >
              {column.isSorted ? (
                column.isSortedDesc ? (
                  <i className="far fa-arrow-down" />
                ) : (
                  <i className="far fa-arrow-up" />
                )
              ) : (
                <i className="far fa-arrows-v" />
              )}
            </span>
          )}
        </span>
        {column.canFilter &&
          column.render("Filter", {
            column,
            id: column.id,
          })}
      </span>
    </th>
  );
};
