import { format } from "date-fns";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { useParentContext } from "../../contexts/ParentContext";
import { AddLearnerButton } from "./AddLearnerButton";
import { getLearnerAccountStatus } from "./getLearnerAccountStatus";
import { SubscriptionStatusPill } from "./SubscriptionStatusPill";
import { PropsWithChildren, ReactNode } from "react";
import {
  FormattedLearnerSubscriptionPlan,
  Learner,
  LearnerSubscriptionDetails,
} from "../../types";
import clsx from "clsx";
import { ReactivateLink } from "./ReactivateLink";
import { ResendInviteLink } from "./ResendInviteLink";
import { ParentEditAccountButton } from "./ParentEditAccountButton";
import Button from "../../../student/components/generic/button";
import { REACT_APP_LEARNER_LINK } from "../../../utils";
import { canModifySubscription } from "../../utils/canModifySubscription";
import { getOpenSeats } from "../../utils/getOpenSeats";
import { Infotip } from "../../../shared/Infotip";
import ToggleChallengeMode from "./ToggleChallengeMode";
import { isSubscriptionActive } from "../../utils/isSubscriptionActive";
import { useReactivationContext } from "./ReactivationContext";
import { CopyInviteLink } from "../Parent/CopyInviteLink";

const TableNarrowField: React.FC<
  PropsWithChildren<{ label: string | ReactNode }>
> = ({ label, children }) => (
  <div className="flex flex-col gap-2">
    <h4 className="font-bold">{label}</h4>
    <div>{children}</div>
  </div>
);

const TableNarrowRow: React.FC<
  PropsWithChildren<{
    learner: Learner;
    subscription: LearnerSubscriptionDetails;
    onClickReactivation: () => void;
    withBorder?: boolean;
  }>
> = ({ learner, subscription, onClickReactivation, withBorder = false }) => {
  const { learner: parent } = useLearnerContext();
  const status = getLearnerAccountStatus(learner);
  const parentStatus = parent ? getLearnerAccountStatus(parent) : null;
  const isLearner = learner.entitlements.includes("learner");

  return (
    <div
      className={clsx(
        "relative flex flex-col gap-4",
        withBorder && "border-t border-dm-charcoal-100 pt-8"
      )}
    >
      <ParentEditAccountButton
        user={learner}
        className={clsx(
          "absolute right-0 text-lg",
          withBorder ? "top-5" : "-top-3"
        )}
      />
      <TableNarrowField label="Name">
        {learner.first} {learner?.last}
      </TableNarrowField>
      <TableNarrowField label="Account Type">
        {learner.entitlements.includes("parent") ? "Parent" : "Learner"}
      </TableNarrowField>
      <TableNarrowField label="User Name/Email">
        {learner.email}
      </TableNarrowField>
      <TableNarrowField label="Password">{"*".repeat(16)}</TableNarrowField>
      {isLearner && (
        <TableNarrowField
          label={
            <>
              Challenge Mode{" "}
              <Infotip
                options={{
                  tooltipRight: true,
                  maxWidth: 200,
                  aligned: "center",
                }}
                message="Courses too easy? Enable challenge mode to see fewer basic problems and more challenging problems. Most content will be the same in each section whether or not Challenge Mode is enabled."
              >
                <i
                  className="far fa-info-circle text-lg font-normal leading-none text-dm-gray-200"
                  aria-hidden="true"
                ></i>
              </Infotip>
            </>
          }
        >
          <ToggleChallengeMode learner={learner} parentLearner={parent} />
        </TableNarrowField>
      )}
      <TableNarrowField label="Status">
        {<SubscriptionStatusPill status={status} />}
      </TableNarrowField>
      <TableNarrowField label="Learner Start Date">
        {status === "inactive" ? (
          <Button type="link" onClick={onClickReactivation}>
            Reactivate Learner
          </Button>
        ) : status === "pending" ? (
          <div className="flex flex-col items-start">
            <ResendInviteLink learnerId={learner._id} className="!py-1" />
            {learner.inviteCode && (
              <CopyInviteLink
                inviteCode={learner.inviteCode}
                className="!py-1"
              />
            )}
          </div>
        ) : status === "canceled" && parentStatus !== "canceled" ? (
          <ReactivateLink subscription={subscription} learner={learner} />
        ) : (
          format(new Date(learner.createdAt), "MM/dd/yy")
        )}
      </TableNarrowField>
    </div>
  );
};

export const AccountsTableNarrow: React.FC<{
  subscription: LearnerSubscriptionDetails;
  plan: FormattedLearnerSubscriptionPlan;
}> = ({ subscription, plan }) => {
  const { learner: parent } = useLearnerContext();
  const { learners } = useParentContext();
  const { openReactivationModal } = useReactivationContext();

  const openSeats = getOpenSeats(subscription.quantity, learners);
  const canModify = canModifySubscription(subscription);

  const completeSignupLink = `${REACT_APP_LEARNER_LINK}/parent/learners/add`;

  return (
    <div className="flex w-full flex-col gap-6 text-sm lg:hidden">
      {learners.map((learner) => (
        <TableNarrowRow
          key={learner._id}
          subscription={subscription}
          learner={learner}
          withBorder
          onClickReactivation={() => openReactivationModal([learner])}
        />
      ))}

      {openSeats > 0 &&
        canModify &&
        Array.from({ length: openSeats }).map((_, index) => (
          <div
            className="relative flex flex-col gap-4 border-t border-dm-charcoal-100 pt-8"
            key={`learner_info_needed-${index}`}
          >
            <TableNarrowField label="Learner Info Needed">
              <Button
                className="flex h-10 max-h-6 w-fit items-center justify-center gap-2 !p-0"
                href={completeSignupLink}
                type="link"
              >
                Complete Learner Sign Up
              </Button>
            </TableNarrowField>
          </div>
        ))}

      {(parent?.subscriptionWillCancel || !isSubscriptionActive(parent)) && (
        <Button onClick={() => openReactivationModal(learners)}>
          Reactivate All Learner Subscriptions
        </Button>
      )}

      {canModify && (
        <>
          {openSeats > 0 && (
            <Button href={completeSignupLink} className="mt-8">
              Complete Learner Sign Up
            </Button>
          )}
          <AddLearnerButton
            className={clsx(
              "flex h-10 items-center justify-center gap-2 p-0",
              openSeats <= 0 && "mt-8"
            )}
            subscription={subscription}
            plan={plan}
            outline={openSeats > 0}
          />
        </>
      )}
    </div>
  );
};
