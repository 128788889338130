import { useState, useEffect, useRef } from "react";
import { NavLink, useParams } from "react-router-dom";
import clsx from "clsx";
import { SwitchHorizontalIcon } from "@heroicons/react/outline";
import IconProgress from "../../IconProgress";
import IconStatus from "../../IconStatus";
import { useCourseContext } from "../../../contexts/CourseContext";
import { useLearnerContext } from "../../../contexts/LearnerContext";
import { LearnerAssignmentTypes } from "../../../types";
import { scrollToView } from "../../../../student/utils";
import { REACT_APP_LEARNER_LINK } from "../../../../utils";

type Props = {
  type: LearnerAssignmentTypes;
  sk: string;
  index: number;
  score: number;
  required: number;
  record: number;
  skillComplete?: boolean;
  isSubmitted: boolean;
  courseId: string;
  subunitId?: string;
  coursePath?: string;
  unitPath?: string;
  subunitPath?: string;
  wasSwapped?: boolean;
};

export default function SubunitSkill(props: Props): JSX.Element {
  const courseContext = useCourseContext();
  const learnerContext = useLearnerContext();
  const { subunitPath, unitPath, coursePath, indexOfSkill, assignmentType } =
    useParams();
  const buttonRef = useRef<HTMLDivElement>(null);
  const [isSkillHover, setIsSkillHover] = useState<boolean>(false);
  const subunitData = courseContext.getSubunitData(
    subunitPath,
    unitPath,
    coursePath
  );
  const skillName = subunitData?.skills[props.index]?.skillName;

  useEffect(() => {
    if (
      (["unitTest", "courseTest"].includes(props.type) ||
        (subunitData?.id === props.subunitId &&
          assignmentType === props.type)) &&
      indexOfSkill === props.index.toString()
    )
      scrollToView(buttonRef, "instant", "center");
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexOfSkill, assignmentType]);

  const baseUrl =
    props.type === "unitTest"
      ? `${REACT_APP_LEARNER_LINK}/${props.coursePath}/${props.unitPath}/unittest`
      : props.type === "courseTest"
      ? `${REACT_APP_LEARNER_LINK}/${props.coursePath}/coursetest`
      : `${REACT_APP_LEARNER_LINK}/${props.coursePath}/${props.unitPath}/${
          props.subunitPath
        }/${props.type.toLowerCase()}`;

  return (
    <div
      className="mb-2 last:mb-0"
      ref={buttonRef}
      key={`skill-${props.subunitId}-${props.type}-${props.sk}-${props.index}`}
    >
      <NavLink
        to={`${baseUrl}/${props.index}`}
        className={({ isActive }) =>
          clsx(
            isActive ? "bg-dm-brand-blue-100" : "",
            "block rounded px-3 py-1 text-sm !leading-none text-dm-charcoal-800 hover:bg-dm-brand-blue-100",
            "flex flex-row items-center gap-x-2"
          )
        }
        onClick={() => {
          if (props.type === "practice") {
            learnerContext.navigateProblem();
          }
        }}
        onPointerEnter={() => {
          if (!isSkillHover) {
            setIsSkillHover(true);
          }
        }}
        onPointerLeave={() => {
          if (isSkillHover) {
            setIsSkillHover(false);
          }
        }}
      >
        {({ isActive }) => (
          <>
            {props.type === "practice" ? (
              <IconProgress
                isActive={isActive || isSkillHover}
                score={props.score}
                required={props.required}
                record={props.record}
              />
            ) : (
              <IconStatus
                skillComplete={props.skillComplete}
                assignmentComplete={props.isSubmitted}
                skillCorrect={props.score}
                isActive={isActive || isSkillHover}
              />
            )}
            <div className="text-sm">
              {props.type !== "practice" || !skillName
                ? `Question ${props.index + 1}`
                : skillName}
            </div>
            {props.wasSwapped && (
              <span className="flex items-center rounded-full bg-dm-purple-200 p-0.5 leading-none text-dm-purple-800">
                <SwitchHorizontalIcon className="w-3" />
              </span>
            )}
          </>
        )}
      </NavLink>
    </div>
  );
}
