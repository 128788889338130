import clsx from "clsx";
import { Cell } from "react-table";
import { useDataTable } from "./DataTableContext";

export const TableBodyCell: React.FC<{ cell: Cell }> = ({ cell }) => {
  const { isFetching } = useDataTable();
  return (
    <td
      {...cell.getCellProps()}
      className={clsx(
        "whitespace-nowrap bg-white p-4",
        cell.column.align === "right" ? "text-right" : "text-left",
        isFetching && "opacity-50"
      )}
    >
      {cell.render("Cell")}
    </td>
  );
};
