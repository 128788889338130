import mixpanelPlugin from "@analytics/mixpanel";
import { DELTAMATH_MIXPANEL_TOKEN } from "../../../utils";
export const MIXPANEL_PLUGIN = mixpanelPlugin({
  token: DELTAMATH_MIXPANEL_TOKEN || "abc123",
  pageEvent: "pageView",
  options: {
    // 5% of sessions will be recorded
    record_sessions_percent: 5,
    record_mask_text_selector: "[type=password]",
  },
});
