import clsx from "clsx";
import Button from "../../../student/components/generic/button";
import { REACT_APP_HOMEPAGE_LINK } from "../../../utils";

export const CopyInviteLink: React.FC<{
  inviteCode: string;
  isLarge?: boolean;
  className?: string;
}> = ({ inviteCode, isLarge = false, className }) => {
  const link = `${REACT_APP_HOMEPAGE_LINK}/app/learner/invite/${inviteCode}`;
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(link);
    } catch (e) {
      // Ignore. Probably failed the write permission check; requires https in
      // some browsers
      console.error(e);
      return;
    }
  };
  return (
    <Button type="link" onClick={copyToClipboard} className={className}>
      Copy Invite Link
      <i className={clsx("far fa-link", isLarge ? "ml-2" : "ml-1")} />
    </Button>
  );
};
