import { useState, useEffect, Fragment } from "react";
import { useDMQuery } from "../../../utils";
import { LearnerData, LearnerSummary } from "../../types";

const LearnerSalesSummary: React.FC = () => {
  const [summary, setSummary] = useState<Array<LearnerSummary>>([]);
  const [recent, setRecent] = useState<number>(0);

  const statuses: Array<LearnerSummary["status"]> = [
    "Trialist",
    "Active",
    "Canceled",
    "Inactive",
  ];

  const { isLoading, data } = useDMQuery({
    path: "/manager_new/parent-portal/learner-sales-data",
    method: "get",
    queryOptions: {
      refetchOnWindowFocus: false,
    },
  });

  useEffect(() => {
    if (data?.summary?.length > 0) {
      setSummary(data.summary);
    }

    if (data?.recent) {
      setRecent(data.recent);
    }
  }, [data]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex w-[90%] flex-row justify-between gap-x-4">
      {statuses.map((currentStatus: LearnerSummary["status"]) => {
        const { status, annual, monthly, count } = summary.find(
          (learnerSummary: LearnerSummary) =>
            learnerSummary.status === currentStatus
        ) || { status: currentStatus, monthly: 0, annual: 0, count: 0 };
        return (
          <Fragment key={status}>
            <div className="flex w-[18%] flex-col items-center">
              <div className="m-4 grid h-40 w-full grid-cols-1 grid-rows-4 gap-1 rounded-lg bg-white shadow-md">
                <div className="col-span-1 m-2 self-start text-sm font-semibold">
                  {status}
                </div>
                <div className="col-span-1 row-span-2 w-full grow self-center text-center text-3xl text-indigo-500">
                  {count}
                </div>
              </div>
              <div className="m-4 h-40 w-full rounded-lg bg-white py-1 shadow-md">
                <div className="m-2 text-sm font-semibold">{status}</div>
                <div className="mx-2 w-full text-xl">
                  Monthly: ${monthly.toFixed(2)}
                </div>
                <div className="mx-2 mb-2 w-full text-xl">
                  Annual: ${annual.toFixed(2)}
                </div>
              </div>
            </div>
          </Fragment>
        );
      })}
      <div className="flex w-[18%] flex-col items-center">
        <div className="m-4 grid h-40 w-full grid-cols-1 grid-rows-4 rounded-lg bg-white shadow-md">
          <div className="col-span-1 m-2 text-sm font-semibold">
            Active Subscribers - Past 30 Days
          </div>
          <div className="row-span-2 w-full self-center text-center text-3xl text-indigo-500">
            {recent}
          </div>
        </div>
        <div className="m-4 grid h-40 w-full grid-cols-1 grid-rows-4 rounded-lg bg-white shadow-md">
          <div className="col-span-1 m-2 text-sm font-semibold">
            Canceled During Trial
          </div>
          <div className="col-span-1 row-span-2 w-full self-center text-center text-3xl text-indigo-500">
            {
              summary.find((summary) => summary.status === "Trial Canceled")
                ?.count
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearnerSalesSummary;
