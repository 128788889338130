import { createContext, useContext, useEffect, useMemo, useState } from "react";
import {
  AggregationOptions,
  ColumnOverrides,
  TableJsonResponse,
  TableRow,
} from "./types";
import {
  Column,
  FilterValue,
  SortingRule,
  TableInstance,
  useColumnOrder,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useBoolean, useScrollLock } from "usehooks-ts";
import { useQuery, useQueryClient } from "react-query";
import { executeQuery } from "../../../utils";
import { noop, omit } from "lodash";
import { buildColumnConfig } from "./buildColumnConfig";
import {
  getPersistentTableState,
  setPersistentTableState,
} from "./persistentTableState";

const FILTER_DEBOUNCE = 250;

interface DataTableDefaults {
  pageSize?: number;
  sortBy?: SortingRule<TableRow>[];
  filterBy?: FilterValue[];
}

interface DataTableContextType {
  source: string;
  data?: TableJsonResponse;
  isFetching: boolean;
  isExpanded: boolean;
  toggleExpanded: () => void;
  table: TableInstance<TableRow>;
  sortable: string[];
  error: unknown | undefined;
  externalFilterBy: FilterValue[];
  setExternalFilterBy: (filterBy: FilterValue[]) => void;
}

const DataTableContext = createContext<DataTableContextType>({
  source: "",
  isFetching: false,
  isExpanded: false,
  toggleExpanded: noop,
  table: {} as TableInstance<TableRow>,
  sortable: [],
  error: undefined,
  externalFilterBy: [],
  setExternalFilterBy: noop,
});

/** Hook for accessing details for the current data table context */
export function useDataTable<D extends object = Record<string, unknown>>() {
  const {
    isFetching,
    data,
    isExpanded,
    toggleExpanded,
    table,
    source,
    sortable,
    error,
    setExternalFilterBy,
    externalFilterBy,
  } = useContext(DataTableContext);
  const client = useQueryClient();

  const additionalData = omit(data, [
    "rows",
    "columns",
    "pageCount",
    "pageSize",
  ]) as D;

  return {
    source,
    isFetching,
    columns: data?.columns,
    rows: data?.rows,
    pageCount: data?.pageCount,
    pageSize: data?.pageSize,
    isExpanded,
    toggleExpanded,
    table,
    sortable,
    refetchTableData: () => client.invalidateQueries(source),
    error,
    setExternalFilterBy,
    externalFilterBy,
    ...additionalData,
  };
}

/**
 * Context provider for a data table. Manages context for a table with remote
 * filtering and sorting capabilities. Use with `DataTable` component to
 * display the table and the `useDataTable` hook for accessing details about
 * the table state.
 * @example
 * <DataTableContextProvider
 *  source="/example/data"
 * >
 *  <DataTable />
 * </DataTableContextProvider>
 */
export function DataTableContextProvider({
  children,
  source,
  sortable = [],
  filterable = [],
  defaults = {},
  fetchOnLoad = false,
  columnOverrides = {},
}: {
  children: React.ReactNode;
  source: string;
  sortable?: string[];
  filterable?: string[];
  defaults?: DataTableDefaults;
  fetchOnLoad?: boolean;
  columnOverrides?: ColumnOverrides;
}) {
  const [queryState, setQueryState] = useState<{
    sortBy: SortingRule<TableRow>[];
    filterBy: FilterValue[];
    pageIndex: number;
  }>({
    sortBy: defaults.sortBy ?? [],
    filterBy: defaults.filterBy ?? [],
    pageIndex: 0,
  });

  const [externalFilterBy, setExternalFilterBy] = useState<FilterValue[]>([]);

  // Note: useDebounceValue doesn't work when table.state.filters is the dependency
  const [deboucedFilterBy, setDebouncedFilterBy] = useState<FilterValue[]>([]);

  const { data, isLoading, isRefetching, error } = useQuery<TableJsonResponse>(
    [
      source,
      ...(queryState.sortBy || []).map(
        (s) => `sort-${s.id}-${s.desc ? "desc" : "asc"}`
      ),
      ...queryState.filterBy.map((f) => `filter-${f.id}-${f.value}`),
      ...externalFilterBy.map((f) => `ext-filter-${f.id}-${f.value}`),
      queryState.pageIndex,
    ],
    () => {
      const params: Partial<AggregationOptions> = {};

      const sortByFinal = queryState.sortBy.length
        ? queryState.sortBy
        : defaults.sortBy ?? [];
      params.sort = sortByFinal.reduce(
        (acc, s) => ({ ...acc, [s.id]: s.desc ? -1 : 1 }),
        {} as Record<string, 1 | -1>
      );

      params.filters = [...deboucedFilterBy, ...externalFilterBy].reduce(
        (acc, { id, value }) => {
          return { ...acc, [id]: { type: "regex", value } };
        },
        {}
      );

      params.pageSize = data?.pageSize ?? defaults.pageSize ?? 25;
      params.pageNumber = queryState.pageIndex;

      return executeQuery({
        path: source,
        params: {
          ...params,
          sort: encodeURIComponent(JSON.stringify(params.sort)),
          filters: encodeURIComponent(JSON.stringify(params.filters)),
        },
      });
    },
    {
      enabled: fetchOnLoad || externalFilterBy.length > 0,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: 1000 * 60 * 5, // 5 minutes
      retry: false,
    }
  );

  const { value: isExpanded, toggle: toggleExpanded } = useBoolean();
  const { lock, unlock } = useScrollLock({ autoLock: false });

  const columns: Column<TableRow>[] = useMemo(
    () => buildColumnConfig(data, columnOverrides, filterable),
    // Only want to set this once when the data first loads. Other dependencies
    // should be static.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.columns.length]
  );

  const initialTableState = getPersistentTableState(source);

  const table = useTable(
    {
      autoResetHiddenColumns: false,
      manualFilters: true,
      manualSortBy: true,
      initialState: {
        sortBy: defaults.sortBy,
        pageSize: data?.pageSize ?? 25,
        hiddenColumns: initialTableState.hiddenColumns || [],
        columnOrder: initialTableState.columnOrder || [],
      },
      columns,
      data: data?.rows || [],
      pageCount: data?.pageCount,
      manualPagination: true,
    },
    useFilters,
    useSortBy,
    useColumnOrder,
    usePagination
  );

  useEffect(() => {
    setQueryState({
      sortBy: table.state.sortBy,
      filterBy: deboucedFilterBy,
      pageIndex: table.state.pageIndex,
    });
  }, [deboucedFilterBy, table.state.sortBy, table.state.pageIndex]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedFilterBy(table.state.filters);
    }, FILTER_DEBOUNCE);
    return () => clearTimeout(timeout);
  }, [table.state.filters]);

  useEffect(() => {
    if (isExpanded) {
      lock();
    } else {
      unlock();
    }
    return () => unlock();
  }, [isExpanded, lock, unlock]);

  useEffect(() => {
    setPersistentTableState(source, {
      hiddenColumns: table.state.hiddenColumns || [],
      columnOrder: table.state.columnOrder || [],
    });
  }, [source, table.state.columnOrder, table.state.hiddenColumns]);

  return (
    <DataTableContext.Provider
      value={{
        source,
        data,
        table,
        isFetching: isLoading || isRefetching,
        isExpanded,
        toggleExpanded,
        sortable,
        error,
        externalFilterBy,
        setExternalFilterBy,
      }}
    >
      {children}
    </DataTableContext.Provider>
  );
}
