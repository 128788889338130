import clsx from "clsx";
import ReactTooltip from "react-tooltip";
import { InformationCircleIcon } from "@heroicons/react/solid";
import { isRequestFromLocal } from "../../../utils";

export default function Line({
  lineData,
  problemData,
  resizingData,
  locString, // TEMPORARY FOR DEV TESTING OF SPOKEN KATEX
  hidden,
}: {
  lineData: any;
  problemData: any;
  resizingData?: any;
  locString?: string;
  hidden?: boolean;
}): JSX.Element {
  const { index, line } = lineData;

  const marginTopStyle =
    line?.format?.space !== undefined
      ? { marginTop: `${line.format.space}px` }
      : {};
  const fontSizeStyle =
    line?.format?.size !== undefined ? { fontSize: `${line.format.size}` } : {};
  const inlineStyles = { ...marginTopStyle, ...fontSizeStyle };
  const expStyles = line?.format?.expSize
    ? { fontSize: `${line.format.expSize}` }
    : {};
  const tooltip = line?.exp
    ? {
        "data-for": "problem-tooltip" + index,
        "data-multiline": true,
        "data-place": "left",
      }
    : {};
  // TEMPORARY, to show spoken KaTeX when user clicks an eq or line
  // Don't show on Production
  const spokentooltip = !isRequestFromLocal(window.location.origin)
    ? {}
    : {
        "data-for": `${locString}-spoken-tooltip`,
        "data-tip":
          (line?.spoken instanceof Object && line?.spoken.a11yString) ||
          line?.spoken,
        "data-multiline": true,
        "data-place": "top",
        "data-effect": "solid",
        "data-event": "click",
      };
  const customClasses = clsx(
    line.format?.classes,
    line.format?.answer ? "row-contains-answer-for-print" : null,
    line.format?.copiedFromQuestion
      ? "row-contains-line-copied-from-question"
      : null,
    line.format?.pdfHide ? "row-contains-line-hidden-on-pdf" : null,
    line.hide_from_solution ? "hide-from-solution" : null
  );
  let eqFontSize;
  let eqLeftPercent;
  if (line.type === "eq") {
    eqFontSize =
      resizingData?.[`row-${index}`]?.fontSize !== undefined
        ? { fontSize: `${resizingData?.[`row-${index}`]?.fontSize}em` }
        : {};
    eqLeftPercent =
      resizingData?.[`row-${index}`]?.leftPercent !== undefined
        ? { width: `${resizingData?.[`row-${index}`]?.leftPercent}%` }
        : {};
  }

  return (
    <>
      {line.type === "eq" && (
        <div
          key={`eq-${index}`}
          className={clsx(
            "logic-row eq-type row hasJax mt-9 first:mt-0",
            customClasses,
            hidden && "sr-only"
          )}
          style={inlineStyles}
          data-index={`row-${index}`}
          data-linetype="eq"
          data-eqgroup={line.equationGroup}
          aria-hidden={hidden}
        >
          <div className="sr-only">
            {(line.spoken instanceof Object && line.spoken.a11yJSX) ||
              line.spoken}
          </div>
          <div
            aria-hidden="true"
            className={clsx(
              "resize-katex jax line-equation col-xs-12 col-md-9",
              "max-sm:!px-0"
            )}
            style={{ ...eqFontSize }}
            {...spokentooltip}
          >
            <div
              className="left-equation jax"
              style={{ ...eqLeftPercent }}
              dangerouslySetInnerHTML={{ __html: line.katexLeft }}
              data-index={`eq-left-${index}`}
            ></div>
            <div
              className={clsx("right-equation jax")}
              dangerouslySetInnerHTML={{ __html: line.katexRight }}
              data-index={`eq-right-${index}`}
            ></div>
          </div>
          <div
            className="col-md-3 hidden pl-1 text-sm leading-tight md:block"
            style={expStyles}
            dangerouslySetInnerHTML={{ __html: line.exp ? line.exp : null }}
          ></div>
          {line.exp ? (
            <InformationCircleIcon
              className="absolute right-1 h-4 w-4 text-gray-400 md:hidden"
              data-tip
              {...tooltip}
              aria-hidden={true}
            />
          ) : null}
        </div>
      )}
      {line.type === "line" && (
        <div
          key={`line-${index}`}
          className={clsx(
            "logic-row line-type row hasJax mt-9 first:mt-0",
            customClasses,
            hidden && "sr-only"
          )}
          style={inlineStyles}
          data-index={`row-${index}`}
          data-linetype="line"
          aria-hidden={hidden}
        >
          <div className="sr-only">
            {(line.spoken instanceof Object && line.spoken.a11yJSX) ||
              line.spoken}
          </div>
          <div
            aria-hidden="true"
            className={clsx(
              "resize-katex jax",
              "max-sm:!px-0",
              line?.format?.rowClass
                ? line.format.rowClass
                : "col-md-9 col-xs-12",
              // TODO: erase classes that might not be needed for js or styling, like this maintain-equation-alignment
              line?.format?.maintainEquationAlignment
                ? "maintain-equation-alignment"
                : null
            )}
            dangerouslySetInnerHTML={{ __html: line.katex }}
            data-index={`line-${index}`}
            {...spokentooltip}
          ></div>
          {line.exp ? (
            <>
              <div
                className="col-md-3 hidden pl-1 text-sm leading-tight md:block"
                style={expStyles}
                dangerouslySetInnerHTML={{ __html: line.exp }}
              ></div>
              <InformationCircleIcon
                className="absolute right-1 h-4 w-4 text-gray-400 md:hidden"
                data-tip
                {...tooltip}
                aria-hidden={true}
              />
            </>
          ) : null}
        </div>
      )}
      {line.type === "html" && line.exp !== undefined && (
        <div
          key={`html-line-${index}`}
          className={clsx(
            "html-type row hasJax mt-9 first:mt-0",
            customClasses,
            hidden && "sr-only"
          )}
          style={inlineStyles}
          aria-hidden={hidden}
        >
          <div
            className={clsx(
              "problem-html relative",
              line?.format?.htmlClass
                ? line.format.htmlClass
                : "col-md-9 col-xs-12"
            )}
            key={`html-${index}`}
            dangerouslySetInnerHTML={{
              __html:
                line.html.slice(-5) === ".html"
                  ? problemData?.htmlTemplates?.[line.html.slice(0, -5)]
                  : line.html,
            }}
          ></div>
          {line.exp ? (
            <>
              <div
                className="col-md-3 hidden pl-1 text-sm leading-tight md:block"
                style={expStyles}
                dangerouslySetInnerHTML={{ __html: line.exp }}
              ></div>
              <InformationCircleIcon
                className="absolute right-1 h-4 w-4 text-gray-400 md:hidden"
                data-tip
                {...tooltip}
                aria-hidden={true}
              />
            </>
          ) : null}
        </div>
      )}
      {line.type === "html" && line.exp === undefined && (
        <div
          key={`html-line-${index}`}
          className={clsx(
            "html-type row hasJax mt-9 first:mt-0",
            customClasses,
            hidden && "sr-only"
          )}
          style={inlineStyles}
          aria-hidden={hidden}
        >
          <div
            className={clsx(
              "problem-html relative",
              line?.format?.htmlClass ? line.format.htmlClass : "col-xs-12"
            )}
            key={`html-${index}`}
            dangerouslySetInnerHTML={{
              __html:
                line.html.slice(-5) === ".html"
                  ? problemData?.htmlTemplates?.[line.html.slice(0, -5)]
                  : line.html,
            }}
          ></div>
        </div>
      )}
      {/* TEMPORARY, FOR TESTING OF SPOKEN KATEX. HIDE ON PROD */}
      {!isRequestFromLocal(window.location.origin) ? null : (
        <ReactTooltip
          id={`${locString}-spoken-tooltip`}
          className="max-w-[80%] font-sans text-xs leading-7"
        />
      )}
      {line.exp !== undefined && (
        <ReactTooltip
          id={"problem-tooltip" + index}
          className="max-w-[80%] text-xs"
        >
          <div
            dangerouslySetInnerHTML={{ __html: line.exp ? line.exp : null }}
          ></div>
        </ReactTooltip>
      )}
    </>
  );
}
