import { differenceInDays, format, formatRelative } from "date-fns";
import { capitalize } from "lodash";
import { EmptyCell } from "./EmptyCell";

export const DateCell: React.FC = ({ value }: any) => {
  const d = new Date(value);
  if (!value || isNaN(d.getTime())) {
    return <EmptyCell />;
  }
  const now = new Date();
  const diff = differenceInDays(now, d);
  return (
    <span title={d.toISOString()}>
      {diff <= 5 ? capitalize(formatRelative(d, new Date())) : format(d, "PPp")}
    </span>
  );
};
