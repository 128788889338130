import clsx from "clsx";
import React, { ButtonHTMLAttributes, ForwardedRef, forwardRef } from "react";

export const TableSettingsButton: React.FC<
  {
    active?: boolean;
  } & ButtonHTMLAttributes<HTMLButtonElement>
> = forwardRef(
  (
    { children, active = false, ...props },
    ref: ForwardedRef<HTMLButtonElement>
  ) => (
    <button
      ref={ref}
      className={clsx(
        "flex h-6 items-center justify-center gap-2 rounded border px-2 text-sm data-[headlessui-state=open]:border-dm-charcoal-200",
        active
          ? "border-dm-charcoal-500 bg-dm-charcoal-500 text-white hover:bg-dm-charcoal-500/70"
          : "border-transparent bg-white text-dm-charcoal-500 hover:border-dm-charcoal-200"
      )}
      {...props}
    >
      {children}
    </button>
  )
);

TableSettingsButton.displayName = "TableSettingsButton";
