import { useDocumentTitle } from "usehooks-ts";
import { FeatureList } from "./FeatureList";
import { CoursesList } from "./CoursesList";
import { Hero } from "./Hero";
import { Testimonials } from "./Testimonials";
import { PricingContainer } from "./PricingContainer";
import { Faq } from "./Faq";
import { FooterCta } from "./FooterCta";
import { Footer } from "./Footer";
import { SignupModal } from "./Signup/SignupModal";
import { HomepageSignupContextProvider } from "./HomepageSignupContext";
import { useRef } from "react";
import { Stat } from "./Stat";
import { EmptyLearnerPage } from "../Layouts/EmptyLearnerPage";
import BackToDeltamathBanner from "./BackToDeltamathBanner";
import { useLearnerExperiments } from "../../utils/experiments/useLearnerExperiments";
import { DeltieGenerator } from "./DeltieGenerator/DeltieGenerator";
import { HeroWithPrices } from "./HeroWithPrices";

export const HomePage: React.FC<{
  showPromoPrice?: boolean;
  allowPromoCode?: boolean;
}> = ({ showPromoPrice = false, allowPromoCode = false }) => {
  useDocumentTitle("DeltaMath for Home");
  const { isInExperiment } = useLearnerExperiments();

  const plansRef = useRef<HTMLElement>(null);

  const showPriceInHero = isInExperiment("landingPagePricingC", "variant");

  return (
    <EmptyLearnerPage title={undefined} dontTrackTime>
      <div className="h-full w-full">
        <BackToDeltamathBanner />
        <HomepageSignupContextProvider
          plansRef={plansRef}
          showPromoPrice={showPromoPrice}
        >
          <main className="bg-dm-background-blue flex min-h-dvh w-full flex-col items-center gap-20 bg-dm-background-blue-100 md:gap-32 xl:gap-44">
            {showPriceInHero ? <HeroWithPrices /> : <Hero />}
            {!showPriceInHero && <PricingContainer ref={plansRef} />}
            <FeatureList />
            <CoursesList />
            <Stat />
            {showPriceInHero && <PricingContainer ref={plansRef} />}
            <Testimonials />
            <Faq />
            <DeltieGenerator />
            <FooterCta />
            <Footer />

            <SignupModal allowPromoCode={allowPromoCode} />
          </main>
        </HomepageSignupContextProvider>
      </div>
    </EmptyLearnerPage>
  );
};
