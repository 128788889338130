import { TableSettingsBar } from "./TableSettingsBar";
import clsx from "clsx";
import { BasicContentContainer } from "../BasicContentContainer";
import { DmLoadingSpinner } from "../../utils/functions";
import { useDataTable } from "./DataTableContext";
import { TableHead } from "./TableHead";
import { TableBody } from "./TableBody";
import { TableFoot } from "./TableFoot";

/** Table content for the associated parent DataTableContextProvider  */
export const DataTable: React.FC = () => {
  const { isFetching, columns, pageCount, isExpanded, table, error } =
    useDataTable();

  if (isFetching && !columns) {
    return (
      <BasicContentContainer>
        <DmLoadingSpinner message="" />
      </BasicContentContainer>
    );
  }

  if (error) {
    return (
      <BasicContentContainer>
        <p className="text-sm text-dm-error-500">Unable to load table</p>
      </BasicContentContainer>
    );
  }

  if (columns) {
    return (
      <div
        className={clsx(
          "w-full",
          isExpanded && "fixed inset-0 z-50 h-screen overflow-auto bg-white"
        )}
      >
        <TableSettingsBar />

        <div
          className={clsx(
            !isExpanded && "w-full overflow-auto scroll-smooth rounded-t-lg",
            pageCount === 1 && "rounded-b-lg"
          )}
        >
          <table
            className="w-full table-auto border-collapse"
            {...table.getTableProps()}
          >
            <TableHead />
            <TableBody />
          </table>
        </div>
        <TableFoot />
      </div>
    );
  }

  return <></>;
};
