import { useParams } from "react-router-dom";
import { useCourseContext } from "../../contexts/CourseContext";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { useEffect, useState } from "react";
import { memoize } from "lodash";
import { Course, Learner, UnitProgress } from "../../types";
import { clampForSort, useDMQuery } from "../../../utils";

type CourseResponse = Omit<CourseResult, "kind">;

interface CourseResult {
  kind: "course";
  location: "course" | "unit" | "subunit" | "skill";
  courseName: string;
  queryMatch: string;
  coursePath: string;
}

interface UnitResult {
  kind: "unit";
  unitName: string;
  unitIndex: number;
  unitId: string;
  unitPath: string;
}

interface SubunitResult {
  kind: "subunit";
  subunitName: string;
  subunitIndex: number;
  subunitId: string;
  subunitPath: string;
  unitName: string;
  unitIndex: number;
  unitId: string;
  unitPath: string;
}

export interface SkillResult {
  kind: "skill";
  skillName: string;
  skillIndex: number;
  subunitName: string;
  subunitIndex: number;
  subunitId: string;
  subunitPath: string;
  unitName: string;
  unitIndex: number;
  unitId: string;
  practiceInitialized: boolean;
  unitPath: string;
}

export type SearchResult =
  | UnitResult
  | SubunitResult
  | SkillResult
  | CourseResult;

function caseInsensitiveIncludes(bodyStr: string, searchStr: string): boolean {
  return bodyStr.toLowerCase().includes(searchStr.toLowerCase());
}

function getOrderIndex(order: string[]) {
  return memoize(
    (targetId: string) => order.findIndex((orderId) => orderId === targetId) + 1
  );
}

function generateSearchResults(
  learner: Learner | undefined,
  courseData: Course,
  unitProgress: UnitProgress[],
  query: string
): SearchResult[] {
  const results: SearchResult[] = [];
  const getUnitOrderIndex = getOrderIndex(courseData.unitOrder);

  courseData.units
    ?.slice()
    ?.sort((unitA, unitB) =>
      clampForSort(getUnitOrderIndex(unitA.id) - getUnitOrderIndex(unitB.id))
    )
    .forEach((unit) => {
      const unitName = unit.name;
      const unitIndex = getUnitOrderIndex(unit.id);
      const unitId = unit.id;
      const unitPath = unit.path ?? unitId;
      const getSubunitOrderIndex = getOrderIndex(unit.subunitOrder);
      const currentUnitProgress = unitProgress.find((u) => u.unitId === unitId);

      // Skip results for units we don't display
      if (
        !unit.subunits ||
        unit.subunits.length === 0 ||
        unit.subunitOrder.length === 0 ||
        unitIndex <= 0
      ) {
        return;
      }

      if (caseInsensitiveIncludes(unit.name, query)) {
        results.push({
          kind: "unit",
          unitName,
          unitIndex,
          unitId,
          unitPath,
        });
      }

      unit.subunits
        ?.slice()
        ?.sort((subunitA, subunitB) =>
          clampForSort(
            getSubunitOrderIndex(subunitA.id) -
              getSubunitOrderIndex(subunitB.id)
          )
        )
        .forEach((subunit) => {
          const subunitName = subunit.name;
          const subunitIndex = getSubunitOrderIndex(subunit.id);
          const subunitId = subunit.id;
          const subunitPath = subunit.path ?? subunitId;
          const currentSubunitProgress = currentUnitProgress?.subunits.find(
            (s) => s.subunitId === subunitId
          );
          const practiceInitialized =
            currentSubunitProgress?.practice !== undefined;

          // Skip results for subunits we don't display
          if (subunit.skills.length === 0 || subunitIndex <= 0) {
            return;
          }

          if (caseInsensitiveIncludes(subunit.name, query)) {
            results.push({
              kind: "subunit",
              subunitName,
              subunitIndex,
              subunitId,
              subunitPath,
              unitName,
              unitIndex,
              unitId,
              unitPath,
            });
          }

          subunit.skills
            .filter(({ difficulty }) => {
              // Only include skills that match the learner's difficult level
              // for the subunit
              if (difficulty === "both") {
                return true;
              }
              if (currentSubunitProgress?.difficulty) {
                return difficulty === currentSubunitProgress.difficulty;
              }
              return difficulty === learner?.level;
            })
            .forEach((skill, skillIndex) => {
              if (caseInsensitiveIncludes(skill.skillName, query)) {
                results.push({
                  kind: "skill",
                  skillName: skill.skillName,
                  skillIndex,
                  practiceInitialized,
                  subunitName,
                  subunitIndex,
                  subunitId,
                  subunitPath,
                  unitName,
                  unitIndex,
                  unitId,
                  unitPath,
                });
              }
            });
        });
    });

  return results;
}

export function useCourseSearchResults(query: string): SearchResult[] {
  const { coursePath } = useParams();
  const courseContext = useCourseContext();
  const courseData = courseContext.getCourseData(coursePath);

  const learnerContext = useLearnerContext();
  const learnerProgress = courseData
    ? learnerContext.getProgress(courseData.id)
    : undefined;

  const [results, setResults] = useState<SearchResult[]>([]);

  useEffect(() => {
    if (query.length > 0 && courseData) {
      setResults(
        generateSearchResults(
          learnerContext.learner,
          courseData,
          learnerProgress?.units ?? [],
          query
        )
      );
    } else {
      setResults([]);
    }
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, learnerProgress?.units]);

  return results;
}

export function useAllCoursesSearchResults(query: string): SearchResult[] {
  const [results, setResults] = useState<SearchResult[]>([]);

  useDMQuery<CourseResponse[]>({
    path: "/learner/course/search",
    cacheKey: query,
    params: {
      queryStr: query,
    },
    queryOptions: {
      enabled: query.length > 0,
      onSuccess: (data: CourseResponse[]) => {
        setResults(
          data.map((result) => {
            return {
              kind: "course",
              ...result,
            };
          })
        );
      },
      onError() {
        setResults([]);
      },
    },
  });

  return results;
}
