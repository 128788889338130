import { ColumnInstance } from "react-table";
import { TableRow } from "./types";

export const TableHeaderFilterField: React.FC<{
  column: ColumnInstance<TableRow>;
}> = ({ column }) => (
  <input
    value={column.filterValue || ""}
    onChange={(e) => column.setFilter(e.target.value || undefined)}
    className="focus h-7 rounded border-0 px-2 text-sm text-dm-charcoal-500"
    placeholder="Search"
  />
);
