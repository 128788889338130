import { useState } from "react";
import { LearnerData } from "../../types";
import { executeQuery } from "../../../utils";
import { set } from "lodash";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";

const DownloadLearners: React.FC<{
  includeEmployees: boolean;
  includeFree: boolean;
}> = ({ includeEmployees, includeFree }) => {
  const [isLoading, setIsLoading] = useState(false);
  const toastContext = useDeltaToastContext();

  const formatLearnerForCsv = (learner: LearnerData) => {
    const learnerToFormat = learner as any;
    if (learnerToFormat.parent) {
      learnerToFormat.parent = learner.parent.email;
    }
    const keyMap = {
      first: "First",
      last: "Last",
      email: "Username",
      statusAndDate: "Status",
      login_count: "Login Count",
      last_login: "Last Login",
      last_action: "Last Action",
      recentCourse: "Recent Course",
      pointsEarned: "Points Earned",
      pointsUsed: "Points Redeemed",
      sectionsCompleted: "Sections Completed",
      unitsCompleted: "Units Completed",
      subscriptionTrialStartedAt: "Start Date",
      parent: "Parent",
      interval: "Billing",
      cost: "Cost",
    } as const;

    //list of fields that do not need to show if the learner is a "parent" and actual learners are pending
    const learnerOnlyFields = [
      "first",
      "last",
      "email",
      "login_count",
      "last_login",
      "last_action",
      "mostRecentCourse",
      "pointsEarned",
      "pointsUsed",
      "sectionsCompleted",
      "unitsCompleted",
      "parent",
    ];

    const dateFields = [
      "last_login",
      "last_action",
      "subscriptionTrialStartedAt",
      "endDate",
    ];

    const formattedLearner: Record<string, any> = {};

    for (const key in keyMap) {
      const oldKey = key as keyof typeof keyMap;
      const newKey = keyMap[oldKey];
      if (
        learnerToFormat.account_type === "parent" &&
        learnerOnlyFields.includes(oldKey)
      ) {
        formattedLearner[newKey] =
          oldKey === "parent"
            ? learnerToFormat.email
            : oldKey === "first"
            ? "Pending"
            : "";
      } else if (dateFields.includes(oldKey)) {
        formattedLearner[newKey] = learnerToFormat[oldKey]
          ? new Date(learnerToFormat[oldKey] as string).toLocaleDateString()
          : "N/A";
      } else if (["unitsCompleted", "sectionsCompleted"].includes(oldKey)) {
        formattedLearner[newKey] = learnerToFormat[oldKey]?.length || 0;
      } else if (newKey === "Status") {
        formattedLearner[newKey] = learnerToFormat.statusAndDate?.status;
        formattedLearner["End Date"] = learnerToFormat.statusAndDate?.endDate
          ? new Date(
              learnerToFormat.statusAndDate.endDate as string
            ).toLocaleDateString()
          : "N/A";
      } else {
        formattedLearner[newKey] =
          oldKey === "parent" && !learnerToFormat.parent
            ? "Self"
            : learnerToFormat[oldKey];
      }
    }
    return formattedLearner;
  };

  const downloadCSV = async () => {
    setIsLoading(true);
    try {
      const allData = await executeQuery({
        path: `/manager_new/parent-portal/learners?includeEmployees=${includeEmployees}&includeFree=${includeFree}&downloadAll=true&sort={"last_action": -1}&filters={}`,
      });
      const allLearners: Array<LearnerData> = allData?.learners || [];
      const processedLearners = allLearners.map((learner: LearnerData) =>
        formatLearnerForCsv(learner)
      );
      const headers = Object.keys(processedLearners[0]).join(",");
      const rows = processedLearners.map((learner: any) =>
        Object.values(learner)
          .map((value) => `"${value}"`)
          .join(",")
      );
      const csv = [headers, ...rows].join("\n");
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "learners.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading CSV:", error);
      toastContext.addToast({
        title: "Error",
        message: `Error downloading CSV: ${JSON.stringify(error)}`,
        status: "Error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <button className="text-lg text-dm-brand-blue-500" onClick={downloadCSV}>
        Download Learners
      </button>
      {isLoading && <span className="ml-2">Downloading...</span>}
    </div>
  );
};

export default DownloadLearners;
