import { useEffect, useRef } from "react";
import { useScript } from "usehooks-ts";
import { deltamathAPI } from "../../../../manager/utils";
import { processLoginResponse } from "../../../../utils/auth/processLoginResponse";
import { useUserContext } from "../../../../shared/contexts/UserContext";
import { useHomepageSignupContext } from "../HomepageSignupContext";
import { DELTAMATH_GOOGLE_OAUTH_CLIENT_ID } from "../../../../utils";

export const GoogleSignupButton: React.FC = () => {
  const userContext = useUserContext();
  const scriptStatus = useScript("https://accounts.google.com/gsi/client");
  const containerRef = useRef<HTMLDivElement>(null);
  const { accountType } = useHomepageSignupContext();

  useEffect(() => {
    if (scriptStatus === "ready") {
      window.google.accounts.id.initialize({
        client_id: DELTAMATH_GOOGLE_OAUTH_CLIENT_ID,
        callback: async (response: { credential: string }) => {
          try {
            const loginRequest = await fetch(
              `${deltamathAPI()}/learner/default/create-account/google`,
              {
                method: "POST",
                credentials: "include",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  googleToken: response.credential,
                  accountType,
                }),
              }
            );
            processLoginResponse(await loginRequest.json(), userContext, false);
          } catch (e) {
            console.error(e);
          }
        },
      });

      window.google.accounts.id.renderButton(containerRef.current, {
        theme: "outline",
        size: "large",
        // The container should have a width when this happens. Worst
        // case is it will render a small sign-in button if width is 0
        width: containerRef.current?.clientWidth ?? 0,
      });
    }
  }, [accountType, scriptStatus, userContext]);

  return <div ref={containerRef} />;
};
