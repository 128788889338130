import { NavLink } from "react-router-dom";
import { EmptyCell } from "./EmptyCell";

export const LinkCell: React.FC = ({ value }: any) =>
  value === undefined ? (
    <EmptyCell />
  ) : (
    <NavLink
      className="text-dm-brand-blue-500 underline-offset-4 hover:text-dm-brand-blue-600 hover:underline"
      to={value?.href}
    >
      {value?.text}
    </NavLink>
  );
