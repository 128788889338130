import React from "react";
import { IIntegration } from "./LTILogs";
import JsonDisplay from "../../../shared/JSONDisplay";
import clsx from "clsx";

interface IntegrationProps {
  integrations: IIntegration[];
  isFetching: boolean;
}

const Integrations: React.FC<IntegrationProps> = ({
  integrations,
  isFetching,
}) => {
  return (
    <div className="overflow-auto">
      <h2 className="mb-2 text-lg font-bold">Integrations</h2>
      {integrations.length === 0 ? (
        <p className="text-dm-charcoal-500/80">
          No integrations found for this data set
        </p>
      ) : (
        <div
          className={clsx(
            "flex flex-row space-x-2",
            isFetching && "opacity-70"
          )}
        >
          {integrations?.map((integration, index) => (
            <JsonDisplay key={index} data={integration} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Integrations;
