import { useParentContext } from "../../contexts/ParentContext";
import { LearnerSubscriptionDetails } from "../../types";
import { canModifySubscription } from "../../utils/canModifySubscription";
import { getOpenSeats } from "../../utils/getOpenSeats";
import { InvitedLearnerWarningBanner } from "../Parent/InvitedLearnerWarningBanner";
import { OpenSeatsWarningBanner } from "./OpenSeatsWarningBanner";

export const ProfileWarningBannerSwitcher: React.FC<{
  subscription: LearnerSubscriptionDetails | undefined;
}> = ({ subscription }) => {
  const { learners } = useParentContext();
  if (!subscription) return <></>;

  const openSeats = getOpenSeats(subscription.quantity, learners);

  const invitedLearners = learners.filter(
    (learner) => learner.inviteCode && !learner.inviteAcceptedAt
  );
  const singleLearner =
    learners.length === 1 && invitedLearners.length === 1
      ? learners[0]
      : undefined;

  return (
    <>
      {openSeats > 0 && canModifySubscription(subscription) ? (
        <OpenSeatsWarningBanner
          numSeats={subscription.quantity}
          openSeats={openSeats}
        />
      ) : invitedLearners.length ? (
        <InvitedLearnerWarningBanner singleLearner={singleLearner} />
      ) : null}
    </>
  );
};
