import { AnalyticsPlugin } from "analytics";
import { AnalyticsEvent } from "../types";
import Cookies from "js-cookie";
import { DELTAMATH_BREVO_CLIENT_KEY } from "../../../utils";
import { v4 as uuid } from "uuid";
import * as Sentry from "@sentry/react";

declare global {
  interface Window {
    sib: { equeue: any[]; client_key: string; [key: string]: any };
    sendinblue: any;
  }
}

/**
 * This big blob of text contains problematic checks that may still be
 * experiencing Brevo identification issues. Checks listed here will force the
 * user to regenerate their UUID cookie every page refresh until we confirm
 * they're no longer having issues. This is temporary.
 */
const PROBLEM_CHECKS =
  "NDE3OTUyc2VoZ2FsLnNhdXJhdkBnbWFpbC5jb20=NzI2ODI2bml0aWthLnNhdXJhdkBnbWFpbC5jb20=MjExNTc=c2NoZXVhbmdpZUBnbWFpbC5jb20=NjEwNjA3c3NjaGV1MDg1QGdtYWlsLmNvbQ==MTM2MjA1YWJlbGxueWNAZ21haWwuY29tODEyNTY3YWxpc3RhaXJiZWxsbnljQGdtYWlsLmNvbQ==OTY5MzgwY2MyMzY2NjZAZ21haWwuY29tMzQzNDc1Y2MyMzZAaG90bWFpbC5jb20=MTg0NDUwcmFzYS5pc2FhY3NvbkBnbWFpbC5jb20=MTc2NDE1amFrZWlzYWFjc29uNzlAZ21haWwuY29tODQ0MTI2YXZlcm1hOTlAZ21haWwuY29tMjg5MjY1cHJha3NoaXZlcm1hMTFAZ21haWwuY29tNzU4ODY3bG1hcmllZ2FybmVyMUBnbWFpbC5jb20=MTkyMDk1c29waGllbW91c2UxOEBnbWFpbC5jb20=MjA0MTA1YmF0b3VsOTI1MDFAZ21haWwuY29tMzE0MjI5YnMzOTM2QG55dS5lZHU=NjQxMDc5anRob21hczI2OTVAZ21haWwuY29tNzE4MTE0aXp6ZXJiZWUuZHVuY2FuQGdtYWlsLmNvbQ==NDUyNDQzcmFtcGVudW1hcnRoaUBnbWFpbC5jb20=OTgyNDk3cmFtcGVudW1hcnRoaUBob3RtYWlsLmNvbQ==Nzk1ODkyamVubmlmZXJsZXdpczA4QGdtYWlsLmNvbQ==NjQ5Mjg2bGV3aXNmYW1pbHlob21lc2Nob29saW5nQGdtYWlsLmNvbQ==MzU5ODI2cnBhcm1hcjc1QGdtYWlsLmNvbQ==NDU0MzQ3cnBhcm1hckB1bWljaC5lZHU=ODc5OTEwYW5hbHVjaWFtb2xpbmE4MUBnbWFpbC5jb20=MjQ0OTk2ZmxvcmVzbW9saW5hZGFuaWVsYTE3QGdtYWlsLmNvbQ==NDEzMjE0b2xpc2hjaHVrQGdtYWlsLmNvbQ==OTg5Mzc=b2xlaGxpc2hjaHVrNUBnbWFpbC5jb20=ODY3ODcxcWlubGlzaGVuZzQzMEBnbWFpbC5jb20=Mjc5MDM4a2V2aW5xaW4zMEBncmVlbnNib3JvZGF5Lm9yZw==NjMzMzE1MjY1NTA0";

/** Flag so that we aren't constantly updating problem cookies */
let didRegenerateProblemCheck = false;

/**
 * Used to queue identification requests so that only one is happening at a
 * time, and so that no events are sent while identifying
 */
const identifyQueue: Array<{
  email: string;
  data: Record<string, any>;
  active?: true;
}> = [];

/** A queue for holding tracked events until `identify` is done */
const eventQueue: [string, Record<string, unknown>][] = [];

/**
 * The Brevo UUID cookie name. Brevo creates a cookie with a UUID when it is
 * first initialized and then sends that UUID with every request. Once a user
 * is identified with that UUID, all events and identification will be
 * associated with that Brevo Contact. Brevo does not reset this when
 * identifying a different user. As a result, we have to remember to reset this
 * cookie whenever we the user logs in/out
 */
const BREVO_COOKIE = "sib_cuid";

/**
 * Keep track of which version of this fix a user is seeing. This is to ensure
 * that problems are happening in a current version of the code, not just
 * somone who has kept the tab open without refreshing
 */
const BREVO_COOKIE_FIX_VERSION = 4;

/** Regenerate the Brevo UUID cookie */
function regenerateBrevoCookie() {
  Cookies.set(BREVO_COOKIE, uuid());
}

/**
 * Brevo sets a UUID cookie when you identify a user and then uses that UUID to
 * send events to the user. Once there's a UUID, it'll continue sending events
 * to that contact ~forever. And Brevo doesn't reset the UUID when identifying
 * a different user. So if you log in as one user, logout, then login as
 * another user, the new user's details get saved on the previous user's
 * contact. This puts the user's email in localstorage (obfuscated) and
 * compares that to the user it's currently trying to identify. If there's a
 * mismatch, it clears the Brevo cookie.
 */
function verifyEmailConsistency(email: string): {
  expectedCheckValue: string;
  actualCheckValue: string;
  didReset: boolean;
  fixVersion: number;
} {
  const localStorageKey = "dm_brevo_check";
  const expectedCheckValue = window.btoa(email);
  const actualCheckValue = localStorage.getItem(localStorageKey) || "";
  const isProblemUser = PROBLEM_CHECKS.includes(actualCheckValue);
  let didReset = false;

  if (
    actualCheckValue !== expectedCheckValue ||
    (isProblemUser && !didRegenerateProblemCheck)
  ) {
    regenerateBrevoCookie();
    localStorage.setItem(localStorageKey, expectedCheckValue);
    didReset = true;
    if (isProblemUser) {
      didRegenerateProblemCheck = true;
    }
  }
  return {
    expectedCheckValue,
    actualCheckValue,
    didReset,
    fixVersion: BREVO_COOKIE_FIX_VERSION,
  };
}

/**
 * Track an event for Brevo. Conditionally will queue the event if unable to
 * send immediately
 */
function trackEvent(event: string, properties: Record<string, any>) {
  eventQueue.push([event, properties]);
  flushEventQueue();
}

/** Attempt to send all events in the queue. */
function flushEventQueue() {
  if (!window.sib || !window.sib.track || identifyQueue.length > 0) {
    return;
  }
  while (eventQueue.length > 0) {
    // This is ok since we're checking length in the while condition
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const [event, properties] = eventQueue.shift()!;
    window.sib.track(event, null, {
      data: properties,
    });
  }
}

/**
 * Identify a contact for Brevo. Pushed the event to the queue. If it's the
 * only event in the queue, it will send immediately. Otherwise, it will be
 * processed FIFO
 */
function identify(email: string, data: Record<string, any>) {
  identifyQueue.push({ email, data });
  flushIdentifyQueue();
}

/**
 * Manually sends an idenfication request to Brevo from the identification
 * queue. We queue all identify requests so that only one request is happening
 * at a time. `sib.identify` doesn't provide a way to know when the request is
 * complete, so we have to do this manually.
 */
async function flushIdentifyQueue() {
  if (identifyQueue.length === 0) {
    return;
  }

  const { email, data, active } = identifyQueue[0];

  if (active) {
    return;
  }

  identifyQueue[0].active = true;

  const emailConsistencyResult = verifyEmailConsistency(email);

  try {
    await fetch("https://in-automate.brevo.com/p", {
      headers: {
        accept: "*/*",
        "accept-language": "en-US,en;q=0.9",
        "content-type": "application/json;charset=UTF-8",
        "sec-fetch-dest": "empty",
        "sec-fetch-mode": "cors",
        "sec-fetch-site": "cross-site",
      },
      referrer: window.location.origin,
      referrerPolicy: "strict-origin-when-cross-origin",
      body: JSON.stringify({
        key: DELTAMATH_BREVO_CLIENT_KEY,
        cuid: Cookies.get(BREVO_COOKIE),
        ma_url: window.location.href,
        sib_type: "identify",
        contact: data,
        email_id: email,
      }),
      method: "POST",
      mode: "cors",
      credentials: "omit",
    });
  } catch (e) {
    // Make sure this goes to Sentry
    Sentry.captureException("Error sending Brevo identify request");
  }

  // Track if we had to reset the Brevo cookie
  if (emailConsistencyResult.didReset) {
    trackEvent("brevoIdentReset", emailConsistencyResult);
  }

  // Remove the first item from the queue
  identifyQueue.shift();

  // If there's still an item in the queue, send it
  if (identifyQueue.length > 0) {
    flushIdentifyQueue();
  }

  // Otherwise, track any queued events
  else {
    // We can know that Brevo has received the request, but it seems like they
    // need a little time to _process_ the request. 1 second is about the
    // right amount of time for them to associate the cookie with the learner
    const delay = emailConsistencyResult.didReset ? 1000 : 1;
    setTimeout(() => flushEventQueue(), delay);
  }
}

/**
 * This is an Analytics plugin that implements an interface for sending events to Brevo
 * @see https://getanalytics.io/
 * @see https://getanalytics.io/plugins/writing-plugins/
 */
export const BREVO_PLUGIN: AnalyticsPlugin = {
  name: "brevo",

  initialize: () => {
    window.sib = {
      equeue: [],
      client_key: DELTAMATH_BREVO_CLIENT_KEY || "",
    };
    window.sendinblue = {};
    for (
      let j = ["track", "identify", "trackLink", "page"], i = 0;
      i < j.length;
      i++
    ) {
      (function (k) {
        window.sendinblue[k] = function (...args: any[]) {
          const arg = Array.prototype.slice.call(args);
          (
            window.sib[k] ||
            function () {
              const t: Record<string, any> = {};
              t[k] = arg;
              window.sib.equeue.push(t);
            }
          )(arg[0], arg[1], arg[2], arg[3]);
        };
      })(j[i]);
    }
    const n = document.createElement("script");
    const i = document.getElementsByTagName("script")[0];
    n.type = "text/javascript";
    n.id = "sendinblue-js";
    n.async = !0;
    n.src = `https://sibautomation.com/sa.js?key=${window.sib.client_key}`;
    i.parentNode?.insertBefore(n, i);
    window.sendinblue.page();
  },

  page: () => {
    window.sendinblue.page();
  },

  track: ({ payload }: { payload: AnalyticsEvent<any> }) => {
    trackEvent(payload.event, payload.properties);
  },

  identify: ({
    payload,
  }: {
    payload: { userId: string; traits: Record<string, unknown> };
  }) => {
    if (!payload.traits.email) {
      throw new Error("Email required for Brevo");
    }
    if (
      typeof payload.traits.email !== "string" ||
      payload.traits.email.trim().length === 0
    ) {
      throw new Error("Email is wrong type or blank");
    }

    identify(payload.traits.email, {
      id: payload.userId,
      ...payload.traits,
    });
  },

  loaded: () => {
    return !!window.sendinblue;
  },

  reset: () => {
    regenerateBrevoCookie();
  },
};
