import { useState } from "react";
import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
} from "@heroicons/react/outline";
import Line from "./Line";
import { uniqueId } from "lodash";

export default function HiddenLines({
  lineDataArray,
  problemData,
  resizingData,
  locString, // TEMPORARY FOR DEV TESTING OF SPOKEN KATEX
}: {
  lineDataArray: any;
  problemData: any;
  resizingData?: any;
  locString?: string;
}): JSX.Element {
  const [showSteps, setShowSteps] = useState<boolean>(false);
  const hiddenTextId = uniqueId("show-hide-text");

  const handleClick = () => {
    setShowSteps((showSteps: boolean) => !showSteps);
  };

  const showButtonText =
    lineDataArray[0].line?.format?.hidden === true
      ? "show additional steps"
      : lineDataArray[0].line?.format?.hidden;

  const hideButtonText = showButtonText.includes("show")
    ? showButtonText.replace("show", "hide")
    : "hide additional steps";

  return (
    <>
      <div className="row hasJax mt-9 first:mt-0">
        <div className="col-md-9 col-xs-12 text-center">
          <button
            onClick={handleClick}
            className="text-dm-gray-200 hover:text-dm-gray-500"
            aria-controls={hiddenTextId}
            aria-expanded={showSteps}
          >
            {showSteps ? (
              <span>
                {hideButtonText}
                <ChevronDoubleUpIcon
                  className="ml-2 w-4"
                  aria-label="up arrow"
                />
              </span>
            ) : (
              <span>
                {showButtonText}
                <ChevronDoubleDownIcon
                  className="ml-2 w-4"
                  aria-label="down arrow"
                />
              </span>
            )}
          </button>
        </div>
      </div>
      <div id={hiddenTextId} role="region">
        {lineDataArray.map((lineData: any, index: number) => (
          <Line
            key={"line_data" + index}
            lineData={lineData}
            problemData={problemData}
            resizingData={resizingData}
            locString={locString}
            hidden={!showSteps}
          />
        ))}
      </div>
    </>
  );
}
