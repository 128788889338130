import { format } from "date-fns";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { useParentContext } from "../../contexts/ParentContext";
import { getLearnerAccountStatus } from "./getLearnerAccountStatus";
import { SubscriptionStatusPill } from "./SubscriptionStatusPill";
import { ResendInviteLink } from "./ResendInviteLink";
import { AddLearnerButton } from "./AddLearnerButton";
import {
  FormattedLearnerSubscriptionPlan,
  LearnerSubscriptionDetails,
} from "../../types";
import { ReactivateLink } from "./ReactivateLink";
import Button from "../../../student/components/generic/button";
import { REACT_APP_LEARNER_LINK } from "../../../utils";
import { canModifySubscription } from "../../utils/canModifySubscription";
import { ParentEditAccountButton } from "./ParentEditAccountButton";
import { getOpenSeats } from "../../utils/getOpenSeats";
import { Infotip } from "../../../shared/Infotip";
import ToggleChallengeMode from "./ToggleChallengeMode";
import { isSubscriptionActive } from "../../utils/isSubscriptionActive";
import { useReactivationContext } from "./ReactivationContext";
import { CopyInviteLink } from "../Parent/CopyInviteLink";

export const AccountsTableWide: React.FC<{
  subscription: LearnerSubscriptionDetails;
  plan: FormattedLearnerSubscriptionPlan;
}> = ({ subscription, plan }) => {
  const { learner: parent } = useLearnerContext();
  const { learners } = useParentContext();
  const { openReactivationModal } = useReactivationContext();

  const openSeats = getOpenSeats(subscription.quantity, learners);
  const parentStatus = parent ? getLearnerAccountStatus(parent) : null;

  const canModify = canModifySubscription(subscription);

  const completeSignupLink = `${REACT_APP_LEARNER_LINK}/parent/learners/add`;

  return (
    <div className="hidden w-full flex-col gap-4 lg:flex">
      <table className="w-full table-auto border-separate border-spacing-y-4 text-left text-sm">
        <thead>
          <tr>
            <th>Name</th>
            <th>Account Type</th>
            <th>User Name/Email</th>
            <th>Password</th>
            <th>
              Challenge Mode{" "}
              <Infotip
                options={{ aligned: "center", maxWidth: 300 }}
                message="Courses too easy? Enable challenge mode to see fewer basic problems and more challenging problems. Most content will be the same in each section whether or not Challenge Mode is enabled."
              >
                <i
                  className="far fa-info-circle text-lg font-normal leading-none text-dm-gray-200"
                  aria-hidden="true"
                ></i>
              </Infotip>
            </th>
            <th>Status</th>
            <th>Learner Start Date</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {learners.map((learner) => {
            const status = getLearnerAccountStatus(learner);
            return (
              <tr key={learner._id}>
                <td className="align-top">
                  {learner.first} {learner.last}
                </td>
                <td className="align-top">Learner</td>
                <td className="align-top">{learner.email}</td>
                <td className="align-top">
                  {status === "active" ? "*".repeat(16) : ""}
                </td>
                <td className="align-top">
                  <ToggleChallengeMode
                    learner={learner}
                    parentLearner={parent}
                  />
                </td>
                <td className="align-top">
                  <SubscriptionStatusPill status={status} />
                </td>
                <td className="align-top">
                  {status === "inactive" ? (
                    <Button
                      type="link"
                      onClick={() => openReactivationModal([learner])}
                    >
                      Reactivate Learner
                    </Button>
                  ) : status === "pending" ? (
                    <div className="flex flex-col items-start">
                      <ResendInviteLink
                        learnerId={learner._id}
                        className="!pb-1 !pt-0"
                      />
                      {learner.inviteCode && (
                        <CopyInviteLink
                          inviteCode={learner.inviteCode}
                          className="!py-1"
                        />
                      )}
                    </div>
                  ) : status === "canceled" && parentStatus !== "canceled" ? (
                    <ReactivateLink
                      subscription={subscription}
                      learner={learner}
                    />
                  ) : (
                    format(new Date(learner.createdAt), "MM/dd/yy")
                  )}
                </td>
                <td className="align-top">
                  <ParentEditAccountButton user={learner} />
                </td>
              </tr>
            );
          })}
          {openSeats > 0 &&
            canModify &&
            Array.from({ length: openSeats }).map((_, index) => (
              <tr key={index}>
                <td className="align-top" colSpan={4}>
                  <p className="text-sm font-normal italic leading-5 text-dm-gray-500">
                    Learner Info Needed
                  </p>
                </td>
                <td>
                  <ToggleChallengeMode
                    learner={undefined}
                    parentLearner={parent}
                  />
                </td>
                <td className="align-top">&nbsp;</td>
                <td className="align-top">
                  <Button
                    className="flex h-10 max-h-6 w-fit items-center justify-center gap-2 !p-0"
                    href={completeSignupLink}
                    type="link"
                  >
                    Complete Learner Sign Up
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {(parent?.subscriptionWillCancel || !isSubscriptionActive(parent)) && (
        <Button onClick={() => openReactivationModal(learners)}>
          Reactivate All Learner Subscriptions
        </Button>
      )}

      {canModify && (
        <div className="flex flex-col gap-4">
          {openSeats > 0 && (
            <Button href={completeSignupLink}>Complete Learner Sign Up</Button>
          )}
          <AddLearnerButton
            className="flex h-10 items-center justify-center gap-2 p-0"
            subscription={subscription}
            plan={plan}
            outline={openSeats > 0}
          />
        </div>
      )}
    </div>
  );
};
