import { PropsWithChildren } from "react";

export const WarningBanner: React.FC<
  PropsWithChildren<{
    bannerTitle?: string;
    bannerText?: string;
  }>
> = ({ bannerTitle, bannerText, children }) => {
  return (
    <div className="flex w-[1288px] max-w-full items-center gap-3 rounded-lg border border-dm-warning-800 bg-dm-warning-100 p-4">
      <i
        aria-hidden
        className="far fa-exclamation-triangle text-dm-warning-800"
      />
      <div>
        {bannerTitle && <strong>{bannerTitle} </strong>}
        {bannerText && <span>{bannerText} </span>}
        {children}
      </div>
    </div>
  );
};
