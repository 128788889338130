import { ProfileSection } from "./ProfileSection";
import { useParentContext } from "../../contexts/ParentContext";
import { Learner } from "../../types";
import { ProfileField } from "./ProfileField";
import { ProfilePage } from "./ProfilePage";
import { ProfileFieldGroup } from "./ProfileFieldGroup";
import { ProfileColumn } from "./ProfileColumn";
import { PaymentInformationField } from "./Fields/PaymentInformationField";
import { BillingHistoryField } from "./Fields/BillingHistoryField";
import { BillingFrequencyField } from "./Fields/BillingFrequencyField";
import { AccountsTable } from "./AccountsTable";
import { UpcomingChargesField } from "./Fields/UpcomingChargesField";
import { SubscriptionDetailsField } from "./Fields/SubscriptionDetailsField";
import { CancelSubscriptionModal } from "./Cancellation/CancelSubscriptionModal";
import { CancellationContextProvider } from "./Cancellation/CancellationContext";
import Modal from "../../../student/components/generic/Modal";
import { useEffect, useState } from "react";
import { Payment } from "../Home/Payment/Payment";
import { isSubscriptionActive } from "../../utils/isSubscriptionActive";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { HomepageSignupContextProvider } from "../Home/HomepageSignupContext";
import { useSubscription } from "../../utils/useSubscription";
import { LearnerEditAccountButton } from "./LearnerEditAccountButton";
import { getAddedLearnersType } from "../Parent/AddLearners/getAddedLearnersType";
import { unreachableCase } from "../../../utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { ReactivationContextProvider } from "./ReactivationContext";
import { ConfirmReactivationModal } from "./ConfirmReactivationModal";
import { ProfileWarningBannerSwitcher } from "./ProfileWarningBannerSwitcher";

export const ParentProfile: React.FC = () => {
  const toastContext = useDeltaToastContext();
  const { learners, initialLearners, getAddedLearners, resetInitialLearners } =
    useParentContext();
  const learnerContext = useLearnerContext();
  const jsonUser = localStorage.getItem("user");
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const { subscription, plan } = useSubscription();

  useEffect(() => {
    if (
      learnerContext.learner &&
      !isSubscriptionActive(learnerContext.learner)
    ) {
      setShowPaymentModal(true);
    }
  }, [learnerContext.learner]);

  // Parents end up back here after adding new learners. Show a toast if any
  // new learners were added.
  useEffect(() => {
    if (
      initialLearners === null ||
      initialLearners.length === learners.length
    ) {
      return;
    }

    const addedLearnersType = getAddedLearnersType(getAddedLearners());

    let message: string;
    switch (addedLearnersType) {
      case "email":
        message = "Invite was sent to the learner's email.";
        break;
      case "emails":
        message = "Invites were sent to the learners' emails.";
        break;
      case "username":
        message =
          "The learner can now log in to DeltaMath for Home with the username and password you created.";
        break;
      case "usernames":
        message =
          "The learners can now log in to DeltaMath for Home with the usernames and passwords you created.";
        break;
      case "mixed":
        message = "Invites were sent, and usernames were created.";
        break;
      default:
        unreachableCase(addedLearnersType);
    }

    setTimeout(() => {
      toastContext.addToast({
        status: "None",
        darkMode: true,
        id: "challenge-mode-set",
        message,
      });
    }, 500);

    resetInitialLearners();
  }, [
    getAddedLearners,
    initialLearners,
    learners,
    resetInitialLearners,
    toastContext,
  ]);

  // TODO: error handling for when/if user is null / no access to local storage?
  if (!jsonUser) {
    return null;
  }

  const user: Learner = JSON.parse(jsonUser);

  return (
    <ReactivationContextProvider>
      <CancellationContextProvider>
        <div className="flex w-full flex-col">
          <ProfilePage isParentPage>
            <ProfileWarningBannerSwitcher subscription={subscription} />
            <ProfileSection title="Account & Subscription Information">
              <ProfileColumn>
                <ProfileFieldGroup
                  className="relative sm:min-w-80"
                  title="Your Account"
                >
                  <LearnerEditAccountButton
                    className="absolute right-0 top-0 sm:right-10 lg:right-32"
                    user={user}
                  />
                  <ProfileField label="Account Holder Name">
                    {user.first} {user.last}
                  </ProfileField>
                  <ProfileField label="Account Holder Email">
                    {user.email}
                  </ProfileField>
                  <ProfileField label="Password">{"*".repeat(16)}</ProfileField>
                </ProfileFieldGroup>

                <ProfileFieldGroup title="Your Subscription">
                  <SubscriptionDetailsField
                    subscription={subscription}
                    setShowPaymentModal={() => setShowPaymentModal(true)}
                  />
                </ProfileFieldGroup>
              </ProfileColumn>

              <ProfileColumn>
                <ProfileFieldGroup title="Billing">
                  <UpcomingChargesField subscription={subscription} />

                  <BillingFrequencyField subscription={subscription} />

                  {subscription?.status !== "trialing" && (
                    <BillingHistoryField
                      periodStart={subscription?.periodStart}
                    />
                  )}

                  <PaymentInformationField subscription={subscription} />
                </ProfileFieldGroup>
              </ProfileColumn>
            </ProfileSection>

            <ProfileSection title="Learner Account Management">
              <AccountsTable subscription={subscription} plan={plan} />
            </ProfileSection>

            {/* <TempSubscriptionDebug /> */}
          </ProfilePage>
        </div>

        <Modal
          visible={showPaymentModal}
          onClose={() => setShowPaymentModal(false)}
          closeX
          bodyClassName="!p-0"
          widthClassName="max-w-full w-[1041px] transition-none"
          body={
            <HomepageSignupContextProvider plansRef={null}>
              <Payment
                title="Select a Plan to Renew Access"
                buttonText="Start a New Subscription"
                isRenewal
              />
            </HomepageSignupContextProvider>
          }
        />

        <CancelSubscriptionModal />
        <ConfirmReactivationModal />
      </CancellationContextProvider>
    </ReactivationContextProvider>
  );
};
