const LS_PREFIX = "dm_m";
const LS_KEY = "table";
const LS_VERSION = "v1";

interface TableState {
  hiddenColumns: string[];
  columnOrder: string[];
}

function pathToString(path: string): string {
  const [, ...rest] = path.split("/");
  return rest.join("/");
}

function localStorageKey(source: string) {
  return [
    LS_PREFIX,
    LS_KEY,
    LS_VERSION,
    pathToString(source),
    pathToString(window.location.pathname),
  ].join(":");
}

export function getPersistentTableState(source: string): TableState {
  try {
    const initialColumnState = JSON.parse(
      localStorage.getItem(localStorageKey(source)) ?? "{}"
    );

    return {
      hiddenColumns: initialColumnState.hiddenColumns,
      columnOrder: initialColumnState.columnOrder,
    };
  } catch (e) {
    return {
      hiddenColumns: [],
      columnOrder: [],
    };
  }
}

export function setPersistentTableState(source: string, state: TableState) {
  if (state.columnOrder.length === 0 && state.hiddenColumns.length === 0) {
    localStorage.removeItem(localStorageKey(source));
  } else {
    localStorage.setItem(localStorageKey(source), JSON.stringify(state));
  }
}
