import { useDataTable } from "./DataTableContext";
import { TableHeaderCell } from "./TableHeaderCell";

export const TableHead: React.FC = () => {
  const { table } = useDataTable();
  return (
    <thead className="sticky top-0">
      {table.headerGroups.map((headerGroup) => (
        // `getHeaderGroupProps` includes `key`
        // eslint-disable-next-line react/jsx-key
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <TableHeaderCell key={column.id} column={column} />
          ))}
        </tr>
      ))}
    </thead>
  );
};
