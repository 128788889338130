import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ColumnInstance } from "react-table";
import { TableRow } from "./types";
import { Fragment } from "react";
import { Menu } from "@headlessui/react";
import clsx from "clsx";
import { useDataTable } from "./DataTableContext";

export const SortableColumnItem: React.FC<{
  column: ColumnInstance<TableRow>;
}> = ({ column }) => {
  const { table } = useDataTable();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: column.id });
  const isDisabled = column.isVisible && table.visibleColumns.length === 1;
  return (
    <Menu.Item as={Fragment}>
      <li
        className="flex items-center gap-4 whitespace-nowrap rounded py-1"
        ref={setNodeRef}
        style={{ transform: CSS.Transform.toString(transform), transition }}
        {...attributes}
        {...listeners}
      >
        <button
          className="cursor-grab text-sm opacity-30"
          type="button"
          aria-label="Reorder"
        >
          <i className="fas fa-grip-horizontal" />
        </button>
        {/* Need an onclick here to keep the menu from closing when showing/hiding columns */}
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
        <label
          className={clsx(
            "inline-flex items-center gap-2",
            !column.isVisible && "opacity-50",
            isDisabled
              ? "cursor-not-allowed opacity-50"
              : "cursor-pointer hover:opacity-100"
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <input
            type="checkbox"
            {...column.getToggleHiddenProps()}
            className="!ring-0"
            disabled={isDisabled}
          />
          {column.render("Header")}
        </label>
      </li>
    </Menu.Item>
  );
};
