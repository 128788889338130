import { useState, useEffect, useMemo } from "react";
import { useDMQuery } from "../../../utils";
import { useMutation } from "react-query";
import axios from "axios";
import { deltamathAPI } from "../../utils";
import { LearnerData } from "../../types";
import DeltaMathTable from "../../../shared/DeltaMathTable";
import { DMToggleSingleLabel } from "../../utils/functions";
import { ServerSearchTextFilter } from "../../../shared/table/filters";
import { REACT_APP_LEARNER_LINK } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { LoginIcon } from "@heroicons/react/outline";
import { useDocumentTitle } from "usehooks-ts";
import DownloadLearners from "./DownloadLearners";
import LearnerSalesSummary from "./LearnerSalesSummary";
import clsx from "clsx";

const AllLearners = () => {
  const [rowData, setRowData] = useState<Array<LearnerData>>([]);
  const [includeEmployees, setIncludeEmployees] = useState<boolean>(false);
  const [includeFree, setIncludeFree] = useState<boolean>(false);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [sort, setSort] = useState<Record<string, 1 | -1>>({ last_action: -1 });
  const [aggregationFilters, setaggregationFilters] = useState<{
    email?: { type: "regex"; value: string };
    account_type?: { type: "exact"; value: "learner" };
    ["parent.email"]?: { type: "regex"; value: string };
  }>({});

  const navigate = useNavigate();
  const toastContext = useDeltaToastContext();
  const PAGE_SIZE = 250;

  useDocumentTitle("All Learners");

  const queryParams = useMemo(() => {
    const params = new URLSearchParams();
    params.set("pageSize", PAGE_SIZE.toString());
    params.set("pageIndex", pageIndex.toString());
    params.set("filters", JSON.stringify(aggregationFilters));
    params.set("includeEmployees", includeEmployees.toString());
    params.set("includeFree", includeFree.toString());
    params.set("sort", JSON.stringify(sort));
    return params.toString();
  }, [pageIndex, aggregationFilters, includeEmployees, includeFree, sort]);

  const { isLoading, data } = useDMQuery({
    path: `/manager_new/parent-portal/learners?${queryParams}`,
    cacheKey: [
      "allLearners",
      `${pageIndex}`,
      JSON.stringify(aggregationFilters),
      `${includeEmployees}`,
      `${includeFree}`,
      JSON.stringify(sort),
    ],
    method: "get",
    queryOptions: {
      refetchOnWindowFocus: false,
    },
  });

  const { mutateAsync: impersonateMutate } = useMutation(
    (learnerId: string) => {
      return axios.post(
        `${deltamathAPI()}/impersonate/staff/learner/${learnerId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    }
  );

  const impersonate = async (learnerId: string) => {
    try {
      const response = await impersonateMutate(learnerId);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      navigate(`${REACT_APP_LEARNER_LINK}/dashboard`, {
        state: { from: "/all-learners" },
      });
    } catch (error: any) {
      const message =
        error?.response?.data?.message ||
        "There was an error impersonating the learner";

      toastContext.addToast({
        title: "Error",
        message,
        status: "Error",
      });
    }
  };

  useEffect(() => {
    if (data?.learners?.length > 0) {
      const processedLearners = data.learners.map((learner: LearnerData) => {
        if (learner.account_type === "parent") {
          return {
            ...learner,
            first: "Pending",
            last: "",
            email: "",
            login_count: "",
            last_login: "",
            last_action: "",
            recentCourse: "",
            pointsEarned: "",
            pointsUsed: "",
            sectionsCompleted: "",
            unitsCompleted: "",
            parent: { email: learner.email, _id: learner._id },
            subscriptionTrialStartedAt: learner.subscriptionTrialStartedAt
              ? new Date(
                  learner.subscriptionTrialStartedAt
                ).toLocaleDateString()
              : "-",
          };
        } else {
          return {
            ...learner,
            recentCourse: learner.recentCourse || "",
            pointsEarned: learner.pointsEarned ?? "",
            pointsUsed: learner.pointsUsed ?? "",
            sectionsCompleted: learner.sectionsCompleted.length ?? "",
            unitsCompleted: learner.unitsCompleted.length ?? "",
            last_login: learner.last_login
              ? new Date(learner.last_login).toLocaleDateString()
              : "N/A",
            last_action: learner.last_action
              ? new Date(learner.last_action).toLocaleDateString()
              : "N/A",
          };
        }
      });
      setRowData(processedLearners);
    } else if (Array.isArray(data?.learners)) {
      setRowData([]);
    }

    if (data?.totalPages) {
      setTotalPages(data.totalPages);
    }
  }, [data]);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        totalWidth: 4,
        accessor: "_index",
      },
      {
        Header: "First",
        accessor: "first",
        Cell: ({ value, row }: { value: string; row: any }) => (
          <span
            className={clsx(value === "Pending" ? "italic text-gray-500" : "")}
          >
            {value}
          </span>
        ),
      },
      {
        Header: "Last",
        accessor: "last",
      },
      {
        Header: "Username/Email",
        accessor: "email",
        Filter: ServerSearchTextFilter,
        Cell: ({ value, row }: { value: string; row: any }) => {
          const learnerId = row.original._id;
          return row.original.account_type === "learner" ? (
            <div className="flex" key={learnerId}>
              <div>{value}</div>
              <LoginIcon
                className="ml-1 h-5 w-5 cursor-pointer text-indigo-500"
                onClick={() => impersonate(learnerId)}
              />
            </div>
          ) : (
            ""
          );
        },
      },
      {
        Header: "Status",
        accessor: "statusAndDate.status",
      },
      {
        Header: "Login Count",
        accessor: "login_count",
      },
      {
        Header: "Last Login",
        accessor: "last_login",
        Cell: ({ value, row }: { value: string; row: any }) => {
          if (value && row.original.account_type === "learner") {
            const date = new Date(value).toLocaleDateString();
            return date;
          } else if (row.original.account_type === "learner") {
            return "N/A";
          } else {
            return "";
          }
        },
      },
      {
        Header: "Last Action",
        accessor: "last_action",
      },
      {
        Header: "Recent Course",
        accessor: "recentCourse",
        disableSortBy: true,
      },
      {
        Header: "Points Earned",
        accessor: "pointsEarned",
      },
      {
        Header: "Points Redeemed",
        accessor: "pointsUsed",
      },
      {
        Header: "Sections Completed",
        accessor: "sectionsCompleted",
      },
      {
        Header: "Units Completed",
        accessor: "unitsCompleted",
      },
      {
        Header: "Start Date",
        accessor: "subscriptionTrialStartedAt",
        Cell: ({ value }: { value: string }) => {
          if (value) {
            const date = new Date(value).toLocaleDateString();
            return date;
          } else {
            return "N/A";
          }
        },
      },
      {
        Header: "End Date",
        accessor: "statusAndDate.endDate",
        Cell: ({ value }: { value: string }) => {
          if (value) {
            const date = new Date(value).toLocaleDateString();
            return date;
          } else {
            return "-";
          }
        },
      },
      {
        Header: "Parent",
        accessor: "parent.email",
        Filter: ServerSearchTextFilter,
        Cell: ({ value, row }: { value: LearnerData; row: any }) => {
          const learnerId =
            row.original.account_type === "learner"
              ? row.original.parent?._id
              : row.original._id;
          if (learnerId) {
            return (
              <div className="flex" key={learnerId}>
                <div>{value}</div>
                <LoginIcon
                  className="ml-1 h-5 w-5 cursor-pointer text-indigo-500"
                  onClick={() => impersonate(learnerId)}
                />
              </div>
            );
          } else {
            return "Self";
          }
        },
      },
      {
        Header: "Billing",
        accessor: "interval",
        Cell: ({ value }: { value: "annual" | "monthly" }) => {
          if (value === "annual") {
            return "Annual";
          } else if (value === "monthly") {
            return "Monthly";
          } else {
            return "Unknown";
          }
        },
        disableSortBy: true,
      },
      {
        Header: "Cost",
        accessor: "cost",
        Cell: ({ row, value }: { row: any; value: number }) => {
          if (row.original.freeAccount) {
            return "Free";
          } else {
            return `$${value.toFixed(2)}`;
          }
        },
        disableSortBy: true,
      },
    ],
    []
  );

  const handleFilterChange = (
    filters: Array<{ id: string; value: string }>
  ) => {
    const newFilters: typeof aggregationFilters = {};
    if (!filters.length) {
      setaggregationFilters({});
    } else {
      filters.forEach((filter) => {
        const key = filter.id as keyof typeof aggregationFilters;
        if (key === "email" || key === "parent.email") {
          newFilters[key] = {
            type: "regex",
            value: filter.value,
          };
        }
        if (key === "email") {
          newFilters.account_type = {
            type: "exact",
            value: "learner",
          };
        }
      });
      setaggregationFilters(newFilters);
    }
  };

  const handleSortChange = (
    sortBy: { id: string; desc?: boolean | undefined }[]
  ) => {
    if (
      [
        "first",
        "last",
        "email",
        "login_count",
        "pointsEarned",
        "pointsUsed",
        "sectionsCompleted",
        "unitsCompleted",
        "subscriptionTrialStartedAt",
        "statusAndDate.endDate",
        "last_login",
        "last_action",
        "statusAndDate.status",
        "parent.email",
      ].includes(sortBy[0]?.id)
    ) {
      setSort({
        [sortBy[0].id]: sortBy[0].desc ? -1 : 1,
      });
    }
  };

  return (
    <div className="flex flex-col gap-4 px-4 py-10">
      <h1 className="text-2xl font-bold">All Learners</h1>
      {isLoading && <div className="m-4 text-xl font-semibold">Loading...</div>}
      <LearnerSalesSummary />
      <div className="flex gap-16">
        <DMToggleSingleLabel
          checked={includeEmployees}
          onChange={(checked: boolean) => setIncludeEmployees(checked)}
          label={"Include Employee Created Users"}
        />
        <DMToggleSingleLabel
          checked={includeFree}
          onChange={(checked: boolean) => setIncludeFree(checked)}
          label={"Include Free Accounts"}
        />
        {Array.isArray(data?.learners) && (
          <DownloadLearners
            includeEmployees={includeEmployees}
            includeFree={includeFree}
          />
        )}
      </div>

      <DeltaMathTable
        data={rowData}
        columns={columns}
        options={{
          manualFilterFunction: handleFilterChange,
          manualPagination: {
            onPageChange: setPageIndex,
            //either total pages or if server could not calculate arbitrarily large number to easily ensure pages can prev/next
            pageCount: totalPages || 100000,
          },
          autoResetFilters: false,
          initialState: { pageIndex },
          manualSortFunction: handleSortChange,
        }}
      />
    </div>
  );
};

export default AllLearners;
